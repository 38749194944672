<template>
  <div class="disclosure">
    <h1>HomeStation Installation and Operation manual</h1>
    <p>
      The HomeStation&trade; Installation and Operation Manual, with operating instructions, can be found
      <a
        href="/pdf/HomeStation/HomeStation-Installation-and-Operation-Manual-V1.0.7.pdf"
        class="link"
        target="_blank"
        rel="noopener"
        aria-label="HomeStation Installation and Operation Manual opens in a new window"
        >here</a
      >.
    </p>
    <p>
      For more details, please
      <a
        href="/pdf/HomeStation/HomeStation_SpecSheet_012122.pdf"
        aria-label="HomeStation spec sheet opens in a new window"
        class="link"
        target="_blank"
        rel="noopener"
        >download</a
      >
      our full spec sheet.
    </p>
    <h2>Limited Warranty</h2>
    <h3 class="headline4">Warranty Claims</h3>
    <p>
      To start the warranty claim process, please call
      <a class="link" target="_blank" rel="noopener" href="tel:(833) 532-2778">(833) 532-2778</a> and a customer service
      agent will work with you to troubleshoot any issues you may be experiencing and assist in placing a warranty claim
      if needed.
    </p>
    <p>
      Once your warranty claim has been submitted, you’ll receive a return shipping label and instructions on packing
      the original charger. As soon as the original charger has been received by Electrify America, we will ship a
      replacement unit to you.
    </p>
    <h3 class="headline4">Limited Warranty Coverage</h3>
    <ul>
      <li>
        The limited warranty is provided to the original end user purchaser only, and only when the Product is purchased
        from an authorized seller. Products purchased from unauthorized sellers are not covered by this Limited
        Warranty.
      </li>
      <li>Warranty does not cover any installation costs.</li>
    </ul>
    <p><strong>Please see our warranty policy for further details:</strong></p>
    <a class="link" target="_blank" rel="noopener" href="/pdf/HomeStation/HomeStation-Limited-Warranty.pdf"
      >HomeStation&trade; Level 2 Home Charger Warranty</a
    >
  </div>
</template>

<script>
export default {
  name: 'homestation',
  metaInfo: {
    title: 'HomeStation | Electrify Home',
    meta: [
      {
        name: 'description',
        content: `Electrify Home Level 2 Charger HomeStation product and warranty information`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/homestation/' }],
  },
};
</script>
